
//1
import Q1 from '../../assets/img/iq/q (1)/q1.jpg'
import A1 from '../../assets/img/iq/q (1)/a1 (1).jpg'
import A2 from '../../assets/img/iq/q (1)/a1 (2).jpg'
import A3 from '../../assets/img/iq/q (1)/a1 (3).jpg'
import A4 from '../../assets/img/iq/q (1)/a1 (4).jpg'

//2
import Q2 from '../../assets/img/iq/q (2)/q2.jpg'
import B1 from '../../assets/img/iq/q (2)/a2 (1).jpg'
import B2 from '../../assets/img/iq/q (2)/a2 (2).jpg'
import B3 from '../../assets/img/iq/q (2)/a2 (3).jpg'
import B4 from '../../assets/img/iq/q (2)/a2 (4).jpg'

//3
import Q3 from '../../assets/img/iq/q (3)/q3.jpg'
import C2 from '../../assets/img/iq/q (3)/a3 (2).jpg'
import C1 from '../../assets/img/iq/q (3)/a3 (1).jpg'
import C3 from '../../assets/img/iq/q (3)/a3 (3).jpg'
import C4 from '../../assets/img/iq/q (3)/a3 (4).jpg'

//4
import Q4 from '../../assets/img/iq/q (4)/q (4).jpg'
import D1 from '../../assets/img/iq/q (4)/a (1).jpg'
import D2 from '../../assets/img/iq/q (4)/a (2).jpg'
import D3 from '../../assets/img/iq/q (4)/a (3).jpg'
import D4 from '../../assets/img/iq/q (4)/a (4).jpg'

//5
import Q5 from '../../assets/img/iq/q (5)/q (5).jpg'
import E1 from '../../assets/img/iq/q (5)/a (1).jpg'
import E2 from '../../assets/img/iq/q (5)/a (2).jpg'
import E3 from '../../assets/img/iq/q (5)/a (3).jpg'
import E4 from '../../assets/img/iq/q (5)/a (4).jpg'

//6
import Q6 from '../../assets/img/iq/q (6)/q (6).jpg'
import F1 from '../../assets/img/iq/q (6)/a (1).jpg'
import F2 from '../../assets/img/iq/q (6)/a (2).jpg'
import F3 from '../../assets/img/iq/q (6)/a (3).jpg'
import F4 from '../../assets/img/iq/q (6)/a (4).jpg'

//7
import Q7 from '../../assets/img/iq/q (7)/q (7).jpg'
import G1 from '../../assets/img/iq/q (7)/a (1).jpg'
import G2 from '../../assets/img/iq/q (7)/a (2).jpg'
import G3 from '../../assets/img/iq/q (7)/a (3).jpg'
import G4 from '../../assets/img/iq/q (7)/a (4).jpg'

//8
import Q8 from '../../assets/img/iq/q (8)/q (8).jpg'
import H1 from '../../assets/img/iq/q (8)/a (1).jpg'
import H2 from '../../assets/img/iq/q (8)/a (2).jpg'
import H3 from '../../assets/img/iq/q (8)/a (3).jpg'
import H4 from '../../assets/img/iq/q (8)/a (4).jpg'

//9
import Q9 from '../../assets/img/iq/q (9)/q (9).jpg'
import I1 from '../../assets/img/iq/q (9)/a (1).jpg'
import I2 from '../../assets/img/iq/q (9)/a (2).jpg'
import I3 from '../../assets/img/iq/q (9)/a (3).jpg'
import I4 from '../../assets/img/iq/q (9)/a (4).jpg'

//10
import Q10 from '../../assets/img/iq/q (10)/q (10).jpg'
import J1 from '../../assets/img/iq/q (10)/a (1).jpg'
import J2 from '../../assets/img/iq/q (10)/a (2).jpg'
import J3 from '../../assets/img/iq/q (10)/a (3).jpg'
import J4 from '../../assets/img/iq/q (10)/a (4).jpg'

//11
import Q11 from '../../assets/img/iq/q (11)/q (11).jpg'
import K1 from '../../assets/img/iq/q (11)/a (1).jpg'
import K2 from '../../assets/img/iq/q (11)/a (2).jpg'
import K3 from '../../assets/img/iq/q (11)/a (3).jpg'
import K4 from '../../assets/img/iq/q (11)/a (4).jpg'


//12
import Q12 from '../../assets/img/iq/q (12)/q (12).jpg'
import L1 from '../../assets/img/iq/q (12)/a (1).jpg'
import L2 from '../../assets/img/iq/q (12)/a (2).jpg'
import L3 from '../../assets/img/iq/q (12)/a (3).jpg'
import L4 from '../../assets/img/iq/q (12)/a (4).jpg'

//13
import Q13 from '../../assets/img/iq/q (13)/q (13).jpg'
import M1 from '../../assets/img/iq/q (13)/a (1).jpg'
import M2 from '../../assets/img/iq/q (13)/a (2).jpg'
import M3 from '../../assets/img/iq/q (13)/a (3).jpg'
import M4 from '../../assets/img/iq/q (13)/a (4).jpg'

//14
import Q14 from '../../assets/img/iq/q (14)/q (14).jpg'
import N1 from '../../assets/img/iq/q (14)/a (1).jpg'
import N2 from '../../assets/img/iq/q (14)/a (2).jpg'
import N3 from '../../assets/img/iq/q (14)/a (3).jpg'
import N4 from '../../assets/img/iq/q (14)/a (4).jpg'

//15
import Q15 from '../../assets/img/iq/q (15)/q (15).jpg'
import O1 from '../../assets/img/iq/q (15)/a (1).jpg'
import O2 from '../../assets/img/iq/q (15)/a (2).jpg'
import O3 from '../../assets/img/iq/q (15)/a (3).jpg'
import O4 from '../../assets/img/iq/q (15)/a (4).jpg'

//16
import Q16 from '../../assets/img/iq/q (16)/q (16).jpg'
import P1 from '../../assets/img/iq/q (16)/a (1).jpg'
import P2 from '../../assets/img/iq/q (16)/a (2).jpg'
import P3 from '../../assets/img/iq/q (16)/a (3).jpg'
import P4 from '../../assets/img/iq/q (16)/a (4).jpg'

//17
import Q17 from '../../assets/img/iq/q (17)/q (17).jpg'
import Q_1 from '../../assets/img/iq/q (17)/a (1).jpg'
import Q_2 from '../../assets/img/iq/q (17)/a (2).jpg'
import Q_3 from '../../assets/img/iq/q (17)/a (3).jpg'
import Q_4 from '../../assets/img/iq/q (17)/a (4).jpg'

//18
import Q18 from '../../assets/img/iq/q (18)/q (18).jpg'
import R1 from '../../assets/img/iq/q (18)/a (1).jpg'
import R2 from '../../assets/img/iq/q (18)/a (2).jpg'
import R3 from '../../assets/img/iq/q (18)/a (3).jpg'
import R4 from '../../assets/img/iq/q (18)/a (4).jpg'

//19
import Q19 from '../../assets/img/iq/q (19)/q (19).jpg'
import S1 from '../../assets/img/iq/q (19)/a (1).jpg'
import S2 from '../../assets/img/iq/q (19)/a (2).jpg'
import S3 from '../../assets/img/iq/q (19)/a (3).jpg'
import S4 from '../../assets/img/iq/q (19)/a (4).jpg'

//20
import Q20 from '../../assets/img/iq/q (20)/q (20).jpg'
import T1 from '../../assets/img/iq/q (20)/a (1).jpg'
import T2 from '../../assets/img/iq/q (20)/a (2).jpg'
import T3 from '../../assets/img/iq/q (20)/a (3).jpg'
import T4 from '../../assets/img/iq/q (20)/a (4).jpg'

const iqData = [
    {
        id: '1',
        question: `${Q1}`,
        label: 'questions.a',
        options: [
            { id: 1, value: '1', answer: `${A1}` },
            { id: 2, value: '2', answer: `${A2}` },
            { id: 3, value: '3', answer: `${A3}` },
            { id: 4, value: '4', answer: `${A4}` },
        ]
    },
    {
        id: '2',
        question: `${Q2}`,
        label: 'questions.b',
        options: [
            { id: 1, value: '1', answer: `${B1}` },
            { id: 2, value: '2', answer: `${B2}` },
            { id: 3, value: '3', answer: `${B3}` },
            { id: 4, value: '4', answer: `${B4}` },
        ]
    },
    {
        id: '3',
        question: `${Q3}`,
        label: 'questions.c',
        options: [
            { id: 1, value: '1', answer: `${C1}` },
            { id: 2, value: '2', answer: `${C2}` },
            { id: 3, value: '3', answer: `${C3}` },
            { id: 4, value: '4', answer: `${C4}` },
        ]
    },
    {
        id: '4',
        question: `${Q4}`,
        label: 'questions.d',
        options: [
            { id: 1, value: '1', answer: `${D1}` },
            { id: 2, value: '2', answer: `${D2}` },
            { id: 3, value: '3', answer: `${D3}` },
            { id: 4, value: '4', answer: `${D4}` },
        ]
    },
    {
        id: '5',
        question: `${Q5}`,
        label: 'questions.e',
        options: [
            { id: 1, value: '1', answer: `${E1}` },
            { id: 2, value: '2', answer: `${E2}` },
            { id: 3, value: '3', answer: `${E3}` },
            { id: 4, value: '4', answer: `${E4}` },
        ]
    },
    {
        id: '6',
        question: `${Q6}`,
        label: 'questions.f',
        options: [
            { id: 1, value: '1', answer: `${F1}` },
            { id: 2, value: '2', answer: `${F2}` },
            { id: 3, value: '3', answer: `${F3}` },
            { id: 4, value: '4', answer: `${F4}` },
        ]
    },
    {
        id: '7',
        question: `${Q7}`,
        label: 'questions.g',
        options: [
            { id: 1, value: '1', answer: `${G1}` },
            { id: 2, value: '2', answer: `${G2}` },
            { id: 3, value: '3', answer: `${G3}` },
            { id: 4, value: '4', answer: `${G4}` },
        ]
    },
    {
        id: '8',
        question: `${Q8}`,
        label: 'questions.h',
        options: [
            { id: 1, value: '1', answer: `${H1}` },
            { id: 2, value: '2', answer: `${H2}` },
            { id: 3, value: '3', answer: `${H3}` },
            { id: 4, value: '4', answer: `${H4}` },
        ]
    },
    {
        id: '9',
        question: `${Q9}`,
        label: 'questions.i',
        options: [
            { id: 1, value: '1', answer: `${I1}` },
            { id: 2, value: '2', answer: `${I2}` },
            { id: 3, value: '3', answer: `${I3}` },
            { id: 4, value: '4', answer: `${I4}` },
        ]
    },
    {
        id: '10',
        question: `${Q10}`,
        label: 'questions.j',
        options: [
            { id: 1, value: '1', answer: `${J1}` },
            { id: 2, value: '2', answer: `${J2}` },
            { id: 3, value: '3', answer: `${J3}` },
            { id: 4, value: '4', answer: `${J4}` },
        ]
    },
    {
        id: '11',
        question: `${Q11}`,
        label: 'questions.k',
        options: [
            { id: 1, value: '1', answer: `${K1}` },
            { id: 2, value: '2', answer: `${K2}` },
            { id: 3, value: '3', answer: `${K3}` },
            { id: 4, value: '4', answer: `${K4}` },
        ]
    },
    {
        id: '12',
        question: `${Q12}`,
        label: 'questions.l',
        options: [
            { id: 1, value: '1', answer: `${L1}` },
            { id: 2, value: '2', answer: `${L2}` },
            { id: 3, value: '3', answer: `${L3}` },
            { id: 4, value: '4', answer: `${L4}` },
        ]
    },
    {
        id: '13',
        question: `${Q13}`,
        label: 'questions.m',
        options: [
            { id: 1, value: '1', answer: `${M1}` },
            { id: 2, value: '2', answer: `${M2}` },
            { id: 3, value: '3', answer: `${M3}` },
            { id: 4, value: '4', answer: `${M4}` },
        ]
    },
    {
        id: '14',
        question: `${Q14}`,
        label: 'questions.n',
        options: [
            { id: 1, value: '1', answer: `${N1}` },
            { id: 2, value: '2', answer: `${N2}` },
            { id: 3, value: '3', answer: `${N3}` },
            { id: 4, value: '4', answer: `${N4}` },
        ]
    },
    {
        id: '15',
        question: `${Q15}`,
        label: 'questions.o',
        options: [
            { id: 1, value: '1', answer: `${O1}` },
            { id: 2, value: '2', answer: `${O2}` },
            { id: 3, value: '3', answer: `${O3}` },
            { id: 4, value: '4', answer: `${O4}` },
        ]
    },
    {
        id: '16',
        question: `${Q16}`,
        label: 'questions.p',
        options: [
            { id: 1, value: '1', answer: `${P1}` },
            { id: 2, value: '2', answer: `${P2}` },
            { id: 3, value: '3', answer: `${P3}` },
            { id: 4, value: '4', answer: `${P4}` },
        ]
    },
    {
        id: '17',
        question: `${Q17}`,
        label: 'questions.q',
        options: [
            { id: 1, value: '1', answer: `${Q_1}` },
            { id: 2, value: '2', answer: `${Q_2}` },
            { id: 3, value: '3', answer: `${Q_3}` },
            { id: 4, value: '4', answer: `${Q_4}` },
        ]
    },
    {
        id: '18',
        question: `${Q18}`,
        label: 'questions.r',
        options: [
            { id: 1, value: '1', answer: `${R1}` },
            { id: 2, value: '2', answer: `${R2}` },
            { id: 3, value: '3', answer: `${R3}` },
            { id: 4, value: '4', answer: `${R4}` },
        ]
    },
    {
        id: '19',
        question: `${Q19}`,
        label: 'questions.s',
        options: [
            { id: 1, value: '1', answer: `${S1}` },
            { id: 2, value: '2', answer: `${S2}` },
            { id: 3, value: '3', answer: `${S3}` },
            { id: 4, value: '4', answer: `${S4}` },
        ]
    },
    {
        id: '20',
        question: `${Q20}`,
        label: 'questions.t',
        options: [
            { id: 1, value: '1', answer: `${T1}` },
            { id: 2, value: '2', answer: `${T2}` },
            { id: 3, value: '3', answer: `${T3}` },
            { id: 4, value: '4', answer: `${T4}` },
        ]
    },
]
export default iqData