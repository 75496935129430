import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom"
import Home from "./Home"
import Info from "./Info"
import IQ from './Iq'
import Layout from './Layout'

const NotFound = () => <h1 className="text-white text-center mt-20">Page Not Found</h1>

function App() {

  return (
    <Layout>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iq" element={<IQ />} />
          <Route path="/info" element={<Info />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </HashRouter>
    </Layout>
  )
}

export default App;