import Logo from '../assets/img/logo128.png'
import { Link } from 'react-router-dom'
import iq from '../assets/img/iq-model.png';
import Footer from './Footer'
const Info = () => {
    return (
        <div className="lg:mt-20">
            <div className="flex justify-center p-2">
                <Link to="/">
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <main className="px-5 p-5 bg-black">
                <div className='bg-indigo-300 p-3 flex justify-center'>
                    <img src={iq} alt="iq info" width={300} />
                </div>

                <h1 className="title text-3xl text-center font-bold leading-loose tracking-widest mb-5">
                    IQ TESTLERİ, ZEKİLİK TESTİ ANLAMINA DA GELİR Mİ?</h1>
                <p className="text-center leading-loose tracking-widest text-white mb-5">
                    Zekilik testi, yani zekanın ölçümü için genellikle IQ testi yapılır. Aslında IQ, mantıksal zekamızı ifade eder. Mantıksal zekanın gelişmiş olmasını “zekilik” olarak isimlendirebiliriz ancak “zekanın tümü”nü ifade edeceksek tek başına IQ terimi yeterli olmaz. Zekanın mantıksal becerilerden çok daha geniş bir kavram olduğunu hatırlatmamız gerekir. Bu nedenle, IQ nedir yanıtının dışında EQ ve SQ terimlerini de açıklamak istiyoruz.
                </p>

                <h1 className="title text-3xl text-center font-bold leading-loose tracking-widest mb-5">
                    EQ NEDİR?</h1>
                <p className="text-center leading-loose tracking-widest text-white mb-5">
                    Türkçe karşılığı, duygusal zeka. EQ’su yüksel bireyler, beyinlerinin sağ lobunu daha iyi kullanırlar. Sözel yetenekleri yüksektir. Ayrıca sosyal ilişkilerimiz de EQ ile doğru orantılıdır.
                </p>

                <h1 className="title text-3xl text-center font-bold leading-loose tracking-widest mb-5">
                    SQ NEDİR?</h1>
                <p className="text-center leading-loose tracking-widest text-white mb-5">
                    Ruhsal zekadır. Bireyin kendi içsel dengesi, ruhsal zekası olarak tanımlanabilir. Aslında SQ , bizim IQ ve EQ’muzun toplamı olarak değerlendirilebilir. SQ’nun gelişmesi IQ ve EQ’yu da bu doğrultuda geliştirecektir. SQ, sözel zeka soruları çözerek geliştirilebilir, bu sayede hem hayal gücümüzü hem de sözel zekamızı kullandırıp beyindeki sinapsları harekete geçirecektir.
                </p>


            </main>
            <Footer />
        </div>
    );
}

export default Info;
