
const Index = ({ children }) => {
    return (
        <div className="lg:container px-3 mx-auto">
            {children}
        </div>
    );
}

export default Index;
