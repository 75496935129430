import { Field } from 'react-final-form'
import Wizard from './Wizard'
import Logo from '../../assets/img/logo128.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { toArray } from 'lodash'
import iqData from './data'
import Footer from '../Footer'

const Iq = () => {
    const [result, setResult] = useState("");
    const [resultopen, setResultOpen] = useState(false);
    const [open, setOpen] = useState(true);

    const onSubmit = values => {
        const { questions } = values
        const question = toArray(questions);

        var i = 0;

        if (question[0] === "3") {
            i++
        }
        if (question[1] === "1") {
            i++
        }
        if (question[2] === "4") {
            i++
        }
        if (question[3] === "3") {
            i++
        }
        if (question[4] === "3") {
            i++
        }
        if (question[5] === "1") {
            i++
        }
        if (question[6] === "4") {
            i++
        }
        if (question[7] === "2") {
            i++
        }
        if (question[8] === "1") {
            i++
        }
        if (question[9] === "2") {
            i++
        }
        if (question[10] === "3") {
            i++
        }
        if (question[11] === "1") {
            i++
        }
        if (question[12] === "4") {
            i++
        }
        if (question[13] === "4") {
            i++
        }
        if (question[14] === "4") {
            i++
        }
        if (question[15] === "2") {
            i++
        }
        if (question[16] === "3") {
            i++
        }
        if (question[17] === "1") {
            i++
        }
        if (question[18] === "1") {
            i++
        }
        if (question[19] === "2") {
            i++
        }

        console.log(i)
        setResult(i * 8)
        setOpen(false)
        setResultOpen(true)
    }

    const Answers = () => {
        return (

            <div className="flex justify-center flex-col items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-32 w-32 text-lime-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-lg mb-5 text-white">Zeka testinizin sonucu : </div>
                <div className="font-bold text-3xl bg-white rounded-lg text-black px-5 py-2 mb-5">{result}</div>
                <table className="w-full sm:w-[500px] mb-5 border-collapse border border-gray-500 text-white text-center">
                    <tbody>
                        <tr>
                            <th className="border border-gray-600 p-5 font-bold text-green-500">IQ PUANI</th>
                            <th className="border border-gray-600 p-5 font-bold text-green-500">AÇIKLAMASI</th>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">156 ve üzeri</td>
                            <td className="border border-gray-700 p-2">Dahi</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">140-155 arası çok üstün zeka</td>
                            <td className="border border-gray-700 p-2">Çok üstün zeka</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">126-140 arası üstün zeka</td>
                            <td className="border border-gray-700 p-2">Üstün zeka</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">111-125 arası ileri zeka</td>
                            <td className="border border-gray-700 p-2">İleri zeka</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">91-110 arası normal zeka</td>
                            <td className="border border-gray-700 p-2">Normal zeka</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">76-90 arası sınır zeka</td>
                            <td className="border border-gray-700 p-2">Sınırda zeka</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">51-75 arası hafif gerilik</td>
                            <td className="border border-gray-700 p-2">Hafif zeka geriliği</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">26-50 arası orta gerilik</td>
                            <td className="border border-gray-700 p-2">Orta düzey zeka geriliği</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-700 p-2">0-25 arası ağır gerilik</td>
                            <td className="border border-gray-700 p-2">Ağır zeka geriliği</td>
                        </tr>
                    </tbody></table>


                <Link to="/" className="uppercase text-neutral-100 text-sm hover:opacity-80">
                    Baştan başla
                </Link>
            </div>
        )
    }

    return (
        <div className="lg:mt-20">
            <div className="flex justify-center p-2">
                <Link to="/">
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <main className="px-5 p-5 bg-black">
                <h1 className="title text-4xl text-center font-bold leading-loose tracking-widest mb-5">IQ TEST</h1>

                {open ? (
                    <>
                        <Wizard
                            initialValues={{}}
                            onSubmit={onSubmit}>

                            {iqData.map(value => {
                                return (
                                    <Wizard.Page key={value.id}>
                                        <div className="text-white text-center font-bold text-lg bg-slate-700 px-6 py-3 rounded-full">
                                            {value.id}
                                        </div>
                                        <div>
                                            <img src={value.question} alt="soal" className="w-52 hover:scale-150 ease-out duration-300" />
                                        </div>
                                        <div className="grid grid-cols-4 gap-4 text-center">
                                            {value.options.map(option => (
                                                <div key={option.id} className="bg-gray-900 border p-1">
                                                    <label>
                                                        <img src={option.answer} alt="a" className="w-28 hover:scale-125 ease-out duration-300" />
                                                        <Field
                                                            name={value.label}
                                                            component="input"
                                                            type="radio"
                                                            value={option.value}
                                                        />
                                                    </label>
                                                </div>
                                            )
                                            )}
                                        </div>


                                    </Wizard.Page>
                                )
                            })}
                        </Wizard>
                    </>
                ) : null}

                {resultopen ? (<Answers />) : null}


            </main>
            <Footer />
        </div>
    );
}

export default Iq