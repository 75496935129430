import { Link } from "react-router-dom";
import Logo from '../assets/img/logo512.png'
const Home = () => {
    return (
        <main className="lg:mt-44 text-white px-5 p-5">
            <div className="flex justify-center">
                <img src={Logo} alt="logo" className="w-64" />
            </div>

            <h1 className="title text-2xl sm:text-3xl mb-3 mt-3 text-center font-bold leading-loose tracking-widest">
                IQ Testi Yap, Zihinsel Becerilerini Detaylı İncele!
            </h1>

            <p className="text-center leading-loose tracking-widest mb-5">
                Aşağıdaki testi yaparak zeka ile ilgili (zihinsel) becerilerini test edebilirsin. IQ testi nedir detaylı bilgileri Bilgiler kısmında paylaştık.
            </p>

            <div className="flex justify-center items-center space-x-3">
                <Link to="iq" className="uppercase hover:scale-150 ease-out duration-300 leading-loose bg-sky-600 tracking-widest text-white  px-7 py-3">
                    Uygulamayı Aç
                </Link>

                <Link to="info" className="uppercase bg-gray-700 00 px-5 p-2">
                    Bilgiler
                </Link>
            </div>

        </main>
    );
}

export default Home;
